.mapComponentRow .col-8 {
  display: flex;
  flex-direction: column;
}
.vertical-timeline::before {
  content: unset;
}
.vertical-timeline-element--work::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0.9rem;
  height: 100%;
  width: 4px;
}
.vertical-timeline-element--work.red::before {
  background: #ed141f;
}
.vertical-timeline-element--work.green::before {
  background: #82ca27;
}
.vertical-timeline-element--work.grey::before {
  background: #122d7633;
}
.vertical-timeline-element-date {
  height: 0;
}
.vertical-timeline {
  padding: 0;
}
.vertical-timeline-element-icon {
  box-shadow: none;
}
.vertical-timeline-element-icon {
  border-radius: 50%;
}
.vertical-timeline-element-icon > div {
  width: 100%;
  height: 100%;
  background: #fff;
}
.vertical-timeline-element-icon {
  height: 2.2rem;
  width: 2.2rem;
}
.vertical-timeline::before {
  background: #122d7633;
}
p.vertical-timeline-element-title {
  color: #3d3456;
  opacity: 0.7;
  font-size: 0.65rem;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
p.vertical-timeline-element-title.vetical-time-second {
  margin-bottom: 0;
  color: #111111;
  line-height: 0.85rem;
  font-size: 0.75rem;
  opacity: 1;
}
.vertical-timeline-element-content {
  padding: 0 1em;
  box-shadow: none;
}
.milestone-img {
  height: 90%;
  width: 90%;
  border-radius: 50%;
  background-color: #fff;
}
.milestone-img.imgRound{
  height: 80%;
  width: 80%;
}
.vertical-timeline-element-content span.vertical-timeline-element-date {
  padding: 0;
}
.milestone-img.milestone-port {
  border-radius: 0;
}
.vertical-timeline::before {
  left: 0.9rem;
}
.vertical-timeline::after,
.vertical-timeline-element:last-child::after {
  display: none;
}
.mapIconDetails {
  margin-top: 0.25rem;
}
.legendDiv {
  display: flex;
  align-items: center;
}
.legendDiv img {
  height: 1.5rem;
  margin-right: 0.5rem;
}
.legendDiv span {
  font-size: 0.7rem;
  line-height: 0.85rem;
}
/* @media only screen and (min-width: 768px) {
  .vertical-timeline-element-content .vertical-timeline-element-date {
    font-size: 0.8rem;
    color: #555555;
    padding: 0;
    height: 1.25rem;
  }
} */

.vertical-timeline-element:last-child {
  padding: 0 !important;
}
.vertical-timeline-element {
  margin: 0;
  padding-bottom: 2rem;
}
