.home {
  background-color: #f9fafd;
}
.sidebar {
  background-color: #fff;
  width: 14rem;
  min-width: 14rem;
  border-radius: 1rem;
  overflow: hidden;
  transition: all 0.3s;
  border: 1px solid #eee;
}
.toggleSidebar {
  transition: all 0.3s;
  height: 1rem;
}
.closeSidebar {
  margin-left: -15rem;
  /* width: 0;
  min-width: 0; */
}
.flip {
  transform: rotate(180deg);
}
.logo {
  display: flex;
}
.logo img {
  width: 79%;
  display: block;
  margin: auto;
}
.shipmentstatus button {
  background-color: #3d3456;
  color: #fff;
  font-size: 0.85rem;
  width: 100%;
  display: flex;
  border-radius: 1rem;
  padding: 0.8rem;
  align-items: center;
  border: 0;
}
.loaderFull {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: #0000001f;
  justify-content: center;
  z-index: 5;
}
.advSearchIcon {
  height: 1.5rem;
}
.shipmentstatus button:focus {
  box-shadow: none;
}
.shipmentstatus {
  padding: 0 0.25rem;
}
.shipmentstatus > .col {
  display: flex;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  cursor: pointer;
}
.shipmentstatus button:hover {
  color: #fff;
}
.shipmentstatus img {
  height: 1.2rem;
}
.shipmentstatus .row img,
.shipmentstatus .row svg {
  margin-left: 0.5rem;
  width: 1.5rem;
}
.shipmentstatus .st {
  font-size: 0.85rem;
  color: #3d3456;
}
.activeSidebarButton {
  background-color: #3d3456;
  color: #fff;
  font-size: 0.85rem;
  width: 100%;
  display: flex;
  border-radius: 1rem;
  padding: 0.8rem 0;
  align-items: center;
  border: 0;
}
.st a,
.st a:hover {
  color: #3d3456;
  text-decoration: none;
}
.shipmentstatus .st.gps {
  color: #1111114d;
}
.gps .col {
  display: flex;
}
#excelSave button {
  background-color: transparent;
}
.gps .col div p {
  background: -webkit-linear-gradient(#f06f33, #e42c34, #9e325f);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
}
.home,
.desktop {
  height: 100%;
  display: flex;
  width: 100%;
}
.home {
  padding: 1rem;
}
.main {
  width: calc(100% - 14rem);
  flex-grow: 1;
}
.header {
  height: 3rem;
}
.headerrow {
  display: flex;
  justify-content: space-between;
}
.home .header span {
  font-weight: normal;
}
.headerName {
  display: flex;
  align-items: center;
  flex-grow: 3;
}
.headerName > span.title {
  font-size: 1.3rem;
}
.advSearch .row {
  margin-bottom: 1rem;
}
.advSearch .row:last-of-type {
  margin-bottom: 0rem;
}
.advSearch label {
  margin-bottom: 0.2rem;
  color: #111;
}
.advSearch input {
  border-radius: 1.25rem;
  font-size: 0.8rem;
  padding: 0.3rem 0.6rem;
  background: #3d34561a;
}
.advSearch .etCalendar input {
  padding-top: 0;
  padding-bottom: 0;
  background: transparent;
}
.etCalendar > div {
  border: 0;
}
.etCalendar button.react-daterange-picker__clear-button,
.etCalendar button.react-daterange-picker__calendar-button {
  padding: 0;
}
.advSearch select {
  font-size: 0.8rem;
  padding: 0.3rem 0.6rem;
  background: #3d34561a;
}
#eta,
#etd {
  border-radius: 1.25rem;
  font-size: 0.8rem;
  padding: 0.3rem 0.6rem;
  background: #3d34561a;
  color: #778087;
}
.shpInput label {
  position: relative;
  margin: 0 1rem 0 0;
  background-color: #efefef;
  border-radius: 1.25rem;
  overflow: auto;
}
.shpInput label span {
  text-align: center;
  font-size: 0.9rem;
  padding: 0.3rem 0.6rem;
  display: block;
}
.shpInput label input {
  position: absolute;
  top: -1.5rem;
}
.shpInput input:checked + span {
  background-color: #39304f;
  color: #f7f7f7;
}
.advSearch .modal-content {
  border-radius: 1rem;
}
.advSearch .modal-header {
  border-bottom: 0;
  padding: 1rem 2.25rem 0 1.5rem;
  font-size: 0.7rem;
}
.advSearch .modal-header h5 {
  font-size: 1.5rem;
}
.advSearch .modal-body {
  padding: 0.5rem 1rem;
}
.advSearch .modal-footer {
  border-top: 0;
  padding: 0 0.5rem 1.5rem 1.5rem;
  justify-content: space-evenly;
}
.advSearch.alertDialog .modal-footer {
  /* justify-content: center; */
}
.aside {
  height: calc(100% - 4rem);
}
.aside > .container-fluid {
  height: 100%;
}
.headerOrgContent {
  width: 25rem;
}
.headerOrgheader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.headerOrgheader h5.headerOrgtitle {
  margin-bottom: 0;
}
.headerOrganisation {
  background: transparent;
}
.headerOrgbody {
  margin-bottom: 2rem;
}
.headerOrgfooter button {
  border-radius: 1.25rem;
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.5rem 1.5rem;
  color: #3d3456;
  background-color: #fff;
  border: 1px solid #3d3456;
}
.advSearch.alertDialog .row {
  align-items: center;
}
.advSearch.alertDialog .modal-footer button {
  border-radius: 1.25rem;
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.5rem 1.5rem;
  color: #3d3456;
  background-color: #fff;
  border: 1px solid #3d3456;
}
.advSearch .modal-footer button {
  background-color: #39304f;
  color: #fff;
  border-radius: 1.25rem;
  font-size: 0.8rem;
  padding: 0.3rem 0.6rem;
}
.advSearch .btn-close {
  border: 1px solid #a0a0a0;
  border-radius: 50%;
}
.headermiddle {
  flex-grow: 3;
  justify-content: center;
  display: flex;
  align-items: center;
}
.odue {
  border: 1px solid #eee;
  border-radius: 0.5rem;
  background-color: #fff;
}
.odue > span,
.inv > span {
  font-size: 0.7rem;
  margin: 0;
  color: #afafaf;
}
.odue p {
  font-size: 0.8rem;
  line-height: 0.9rem;
  color: #cc222b;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.inv p {
  font-size: 0.8rem;
  line-height: 0.9rem;
  margin: 0;
  color: #111;
  display: flex;
  justify-content: space-between;
}
.has-search .form-control-feedback {
  position: absolute;
}
.has-search .form-control {
  padding-left: 2.375rem;
  border-radius: 1rem;
}
.has-search .form-control:focus {
  box-shadow: none;
}
.has-search {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
}
.searchInputClr {
  position: absolute;
  right: 0.5rem !important;
  padding: 0.25rem;
  font-size: 0.6rem;
  border-radius: 50%;
  background: transparent;
  display: none;
}
.notify {
  flex-grow: 1.8;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.notify ul.dropdown-menu li.notsubhead,
.notify ul.dropdown-menu li.nothead {
  padding: 0 0.8rem;
  white-space: nowrap;
}
.nothead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nothead span {
  font-size: 0.75rem;
}
.home .header .notsubhead span {
  font-size: 0.9rem;
  color: #111;
}
.home .header .notsubhead p {
  color: #39304f;
}
.notsubhead svg {
  font-size: 1.8rem;
}
.notify ul.dropdown-menu {
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  border: 1px solid #1111114d;
}
.notsubhead {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.notsubhead p {
  margin-bottom: 0;
  white-space: nowrap;
}
.notify .navbar-nav {
  flex-direction: row;
}
.notify .bell {
  border-right: 1px solid #afafaf66;
  height: 1.4rem;
}
.notify svg.userIcon {
  color: #ee7821;
  font-size: 1.8rem;
}
.notify .dropdown {
  display: flex;
  align-items: center;
}
.shipmentTable > div.MuiPaper-root {
  box-shadow: none;
  background-color: transparent;
  /* max-height: calc(100vh - 5.5rem); */
  height: 100%;
  /* overflow-y: hidden;
  width: calc(100% - 0.5rem); */
  overflow-y: auto;
  width: 100%;
}
.shipmentTable > div.MuiPaper-root:hover {
  width: 100%;
  overflow-y: auto;
}
.shipmentTable table thead th.MuiTableCell-head,
.shipmentTable table tbody tr:nth-child(even) td.MuiTableCell-root {
  border-bottom: none;
}
.shipmentTable table thead th.MuiTableCell-head {
  background: #f9fafd;
  position: sticky;
  top: 0;
  z-index: 2;
  color: #12121299;
  font-size: 0.9rem;
  padding: 0.5rem;
}
.shipmentTable table thead th.MuiTableCell-head svg {
  font-size: 0.9rem;
}
.shipmentTable table {
  padding-right: 0.5rem;
  border-collapse: separate;
  border-spacing: 0 0.2rem;
}
.shipmentTable tbody tr.makeStyles-root-1 {
  background-color: #fff;
}
.shipmentTable tr:nth-of-type(2n) td {
  padding: 0 0;
}
.shipmentTable tr:nth-child(odd) td {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5rem;
  font-size: 0.8rem;
  color: #111;
  background-color: #fff;
}
.shipmentTable.homeshipments tr:nth-child(odd) td:nth-of-type(6),
.shipmentTable.homeshipments tr:nth-child(odd) td:nth-of-type(7) {
  min-width: 4rem;
}
.shipmentTable tr:nth-child(odd) td span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.shipmentTable tr:nth-child(odd) td.destOrg {
  width: 10rem;
}
.shipmentTable tr:nth-child(odd) td.orderRef {
  width: 10rem;
}
.shipmentTable tr:nth-child(odd) td span svg {
  height: 1rem;
  width: 1rem;
}
.tMode {
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
}
.shipmentTable tr:nth-child(odd) td:first-of-type {
  border: 1px solid #eee;
  border-right: 0;
  border-radius: 1.5rem 0 0 1.5rem;
  padding-left: 1rem;
}
.shipmentTable tr:nth-child(odd) td:last-of-type {
  border-radius: 0 1.5rem 1.5rem 0;
  border-right: 1px solid #eee;
  padding: 0.5rem;
  /* width: 3rem; */
  padding-right: 1rem;
  overflow: unset;
}
.shipmentTable tr:nth-child(odd) td.notifitd {
  min-width: 3rem;
}
.shipmentTable tr:nth-child(odd) td.shpmentStatus span {
  display: block;
}
.shipmentTable tr:nth-child(odd) td:nth-of-type(7) {
  overflow: unset;
}
.shipmentTable table thead th.MuiTableCell-head:first-of-type {
  padding-left: 1rem;
}
.shipmentTable thead th.destOrg {
  width: 9rem;
}
.shipmentTable tr:nth-child(odd) td.shpmentStatus {
  min-width: 4.5rem;
  text-align: center;
}
.shipmentTable tr:nth-child(odd) td.shpmentStatus img {
  height: 1.8rem;
}
.shpmntdetails > .container-fluid > .row > .col-12 {
  padding: 0.2rem;
}

.milestone-progress-icon {
  margin-bottom: -35px;
  position: relative;
  z-index: 1;
}
.milestone-progress-icon-circle {
  border-radius: 50%;
}
.shpmntdetails .col-12 > div {
  background-color: #fff;
  border-radius: 1.5rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  min-height: 100%;
}
.details .container-fluid {
  padding: 0;
}
.completedShipment {
  background-color: lightgray;
  padding: 0.4rem 0.5rem;
  font-size: 0.65rem;
  border-radius: 3rem;
  text-align: center;
}
.completedShipment span {
  color: #111;
}
.tabledivheading button {
  color: #3d3456;
  border-radius: 2.5rem;
  padding: 0.5rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1rem;
}
.tabledivheading button:active {
  font-size: 0.875rem;
  line-height: 1rem;
}
button.selected {
  background: #3d345626;
}
button.selected:active {
  font-size: 0.875rem;
  line-height: 1rem;
}
.shpSumm label,
.details label {
  font-size: 0.75rem;
  line-height: 0.95rem;
  color: #1111114d;
}
.shpSumm span,
.details span {
  color: #1111114d;
  font-size: 0.75rem;
}
.shpSumm p,
.details p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0.5rem;
}
.shpSumm p,
.details p .details p,
.details p {
  color: #111111;
}
.border-right-light {
  border-right: 1px solid #afafaf66;
}
.border-left-light {
  border-left: 1px solid #afafaf66;
}
.routing .details p {
  margin-bottom: 0.5rem;
  padding-left: 0;
}
.shpSumm,
.details {
  overflow-y: auto;
  width: 100%;
  /* overflow-y: hidden;
  width: calc(100% - 0.5rem); */
  height: 100%;
}
.details {
  margin-left: 0;
}
/* .shpSumm:hover,
.details:hover {
  overflow-y: auto;
  width: 100%;
} */
.swipeablebottomoverview > div > div {
  overflow-y: hidden;
  width: calc(100% - 0.5rem);
  /* max-height: 9rem;
  min-height: 9rem; */
}
.swipeablebottomoverview > div > div > div:hover {
  /* overflow-y: scroll; */
  width: 100%;
}
.shpmntdetails .col-12 > .overview {
  padding: 0;
  max-height: 100%;
  overflow-y: auto;
  height: 100%;
}
header.oviewHeader {
  background-color: #fff;
  color: #636062;
  box-shadow: none;
  position: sticky;
  top: 0;
  z-index: 1;
}
header.oviewHeader > div,
header.oviewHeader > div button {
  min-height: auto;
  min-width: auto;
}
.oviewHeader button {
  color: #3d3456;
  border-radius: 2.5rem;
  padding: 0.5rem 0.8rem;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1rem;
  border: 1px solid #eee;
  margin-right: 0.5rem;
}
.oviewHeader button:active {
  font-size: 0.875rem;
  line-height: 1rem;
}
.oviewHeader button span {
  text-transform: capitalize;
}
header.oviewHeader button.Mui-selected {
  background: #3d345626;
  border: 1px solid transparent;
}
header.oviewHeader .MuiTabs-flexContainer + span {
  display: none;
}
.swipeablebottomoverview {
  overflow-y: auto;
  flex: 1;
}
.swipeablebottomoverview > div,
.swipeablebottomoverview > div > div,
.swipeablebottomoverview > div > div > div,
.detailsummpanel {
  height: 100%;
}
.detailsummpanel {
  width: calc(100vw - 18rem);
}
.detailsummpanelexpand {
  width: calc(100vw - 3rem);
}
button.report,
button.report:active {
  color: #e6292d;
  background-color: transparent;
  border: 1px solid #eee;
  cursor: text;
}
.docDetails button.selected {
  cursor: text;
}
.milestone {
  height: 100%;
  display: flex;
  align-items: center;
}
.milestonerow {
  display: flex;
  justify-content: center;
  overflow-x: auto;
}
.milestonerow .cardContainer {
  text-align: center;
  width: 20%;
}
/* .milestonerow .cardContainer:first-of-type {
  text-align: left;
  flex: 2;
} */
/* .milestonerow .cardContainer:last-of-type {
  text-align: right;
  flex: 2;
} */
.milestonerow .cardSingleContainer {
  text-align: center;
}
.cardimg {
  position: relative;
}
.milestonerow .cardContainer:first-of-type .cardimg .leftline,
.milestonerow .cardContainer:last-of-type .cardimg .rightline {
  display: none;
}

.cardimg img {
  background: #fff;
  padding: 0.2rem;
  height: 1.8rem;
}
/* .cardContainer:first-of-type .cardimg img {
  padding-left: 0.5rem;
}
.cardContainer:last-of-type .cardimg img {
  padding-right: 0.5rem;
} */
.milestonerow .col:first-of-type .cardimg img {
  padding-left: 0;
}
.milestonerow .col:last-of-type .cardimg img {
  padding-right: 0;
}
.cardContainer hr {
  border-top: 0.15rem dashed #12121266;
  border-style: dotted;
  background-color: transparent;
}
hr.completed {
  border-color: #82ca27;
  opacity: 1;
}
.cardimg hr {
  position: absolute;
  width: 50%;
  top: 50%;
  margin: 0;
  z-index: -1;
}
.cardimg hr:first-of-type {
  transform: translate(0%, 0%);
  /* display: none; */
}
.cardimg hr:last-of-type {
  transform: translate(100%, 0%);
  /* display: none; */
}
.milestoneStatus {
  position: relative;
  width: calc(100% - 1rem);
  margin: auto;
}
.progressmilestone {
  position: absolute;
  transform: translate(-35%, -70%);
  background: #fff;
  padding: 0.2rem;
  border-radius: 50%;
  /* border: 2px solid #3e3656; */
}
.progressmilestone img {
  height: 1.5rem;
  width: 1.5rem;
}
.cardContainer p {
  font-size: 0.75rem;
  line-height: 0.85rem;
}
.routing p.title {
  font-size: 0.8rem;
}
.routing p span {
  background-color: #fff;
  padding: 0.2rem;
}
.milestonerow .cardContainer:last-of-type p span {
  padding-right: 0;
}
.milestonerow .cardContainer:first-of-type p span {
  padding-left: 0;
}
.routing p {
  margin-bottom: 0;
  padding: 0 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.routing .milestonerow p:first-of-type {
  min-height: 1.7rem;
  padding: 0 0.5rem;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.routing.milestone .milestonerow p:first-of-type {
  min-height: auto;
  display: block;
  margin-bottom: 0.2rem;
}
p.voyE {
  min-height: 0.75rem;
  font-size: 0.65rem;
  display: flex;
  justify-content: space-between;
}
p.voyE span {
  width: 50%;
  text-align: left;
}
.milestonerow p.voyE.onTime {
  color: #82ca27;
}
.milestonerow p.voyE.delay {
  color: #e6292d;
}
.routing p.st.onTime {
  color: #82ca27;
}
.routing p.st.delay {
  color: #e6292d;
}
button.reportSend,
button.reportSend:active {
  color: #3d3456;
  background-color: #fff;
  border: 1px solid #3d3456;
}
.details .form-group input {
  border: 1px solid #eee;
  border-radius: 1rem;
  font-size: 0.9rem;
}
.docrow {
  max-height: 9rem;
  overflow-y: auto;
  margin-right: -1.5rem;
  flex: 1;
}
.docrow a,
.docrow a:hover {
  text-decoration: none;
}
.docContainer {
  margin-bottom: 0.5rem;
}
.docContainer .row {
  align-items: center;
}
.docContainer img {
  height: 1.5rem;
  width: 1.5rem;
}
.docContainer p {
  margin-bottom: -0.25rem;
  color: #111;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.docContainer span {
  font-size: 0.7rem;
  color: #1111114d;
}
.docContainer .container-fluid {
  border: 1px solid #cac9c9;
  border-radius: 3rem;
}
.alertNote {
  font-size: 0.8rem;
  color: #cc222b;
}

/* Statement of Accoounts */
.shipmentTable.tableStOA tr:nth-child(odd) td:last-of-type {
  /* width: auto; */
  overflow: hidden;
}
.shipmentTable.tableStOA tr td:nth-of-type(2) {
  width: 4rem;
}
.shipmentTable.tableStOA tr td:nth-of-type(3),
.shipmentTable.tableStOA tr td:nth-of-type(8),
.shipmentTable.tableStOA tr td:nth-of-type(9) {
  width: 1rem;
}
.shipmentTable.tableStOA tr td:nth-of-type(5),
.shipmentTable.tableStOA tr td:nth-of-type(6),
.shipmentTable.tableStOA tr td:nth-of-type(7),
.shipmentTable.tableStOA tr td:nth-of-type(10) {
  width: 5rem;
}
.shipmentTable.tableStOA
  table
  tbody
  tr:nth-child(even)
  td.MuiTableCell-root:last-of-type {
  padding-right: 1rem;
}
.shipmentTable.tableStOA #stmOfAcc tr td:last-of-type {
  width: 4rem;
}
.shipmentTable.tableStOA tr:nth-child(odd) td:nth-of-type(4) {
  width: 8rem;
}
.shipmentTable.tableStOA tr td:last-of-type {
  border-radius: 0 1.5rem 1.5rem 0;
  border-right: 1px solid #eee;
  width: 1rem;
  padding-right: 1rem;
}
.shipmentTable.tableStOA tr td:first-of-type {
  border: 1px solid #eee;
  border-right: 0;
  border-radius: 1.5rem 0 0 1.5rem;
  padding-left: 1rem;
  width: 12rem;
}
.shipmentTable.tableStOA tr td svg {
  font-size: 1rem;
}
.shipmentTable.tableStOA tr td,
.shipmentTable.tableStOA table tbody tr:nth-child(even) td.MuiTableCell-root {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.5rem;
  font-size: 0.8rem;
  color: #111;
  background: #fff;
}
.shipmentTable.tableStOA #stmOfAcc tr td,
.shipmentTable.tableStOA
  table#stmOfAcc
  tbody
  tr:nth-child(even)
  td.MuiTableCell-root {
  max-width: none;
}
.shipmentTable.tableStOA #stmOfAcc tr td div,
.shipmentTable.tableStOA#stmOfAcc
  table
  tbody
  tr:nth-child(even)
  td.MuiTableCell-root
  div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.shipmentTable.tableStOA tr.deactivate td,
.shipmentTable.tableStOA
  table
  tbody
  tr.deactivate:nth-child(even)
  td.MuiTableCell-root {
  background: #efefef;
}
.shipmentTable.tableStOA table tbody tr td.MuiTableCell-root:first-of-type {
  border: 1px solid #eee;
  border-right: 0;
  border-radius: 1.5rem 0 0 1.5rem;
  padding-left: 1rem;
  width: 5rem;
}
.shipmentTable.tableStOA
  table#stmOfAcc
  tbody
  tr
  td.MuiTableCell-root:first-of-type
  div {
  width: 5rem;
}
.shipmentTable.tableStOA table thead th.MuiTableCell-head {
  line-height: 1.1rem;
}
.sorth {
  display: flex;
  align-items: center;
}
.shipmentTable.tableStOA
  table
  tbody.loaderProgress
  tr
  td.MuiTableCell-root:first-of-type {
  background: transparent;
  border: 0;
}
/* uselist */
.shipmentTable.tableStOA.userList tr th:last-of-type {
  padding-right: 1.5rem;
}
.shipmentTable.tableStOA.userList tr:nth-child(odd) td:last-of-type {
  width: 8rem;
}
.shipmentTable.tableStOA.userList tr:nth-child(odd) td span svg,
.shipmentTable.tableStOA.userList tr:nth-child(even) td span svg {
  height: 1.2rem;
  width: 1.2rem;
}
/* containerpanel */
.shipmentTable .containerPanel table {
  border: 1px solid #2521211a;
  border-collapse: collapse;
  width: 100%;
}
.shipmentTable .containerPanel tr td,
.shipmentTable .containerPanel tr th {
  border: 1px solid #2521211a;
}
.shipmentTable .containerPanel tr:nth-child(odd) td:first-of-type {
  border: 1px solid #2521211a;
  border-radius: 0;
}
.shipmentTable.tableStOA tr.overdue td,
.shipmentTable.tableStOA
  table
  tbody
  tr.overdue:nth-child(even)
  td.MuiTableCell-root {
  color: #cc222b;
}
.shipmentTable tr:nth-child(odd) td span.invno,
.shipmentTable tr:nth-child(odd) td span.invnoIcon {
  display: inline;
}
.shipmentTable .containerPanel tr:nth-child(odd) td {
  background-color: #f2f2f2;
}
.shipmentTable .containerPanel tr:nth-child(odd) td:last-of-type {
  width: auto;
  border-radius: 0;
}
.shipmentTable .containerPanel tr td:nth-of-type(2),
.shipmentTable .containerPanel tr td:nth-of-type(3) {
  max-width: 4rem;
}
.shipmentTable .containerPanel tr td:nth-of-type(4) {
  max-width: 5rem;
}
.shipmentTable .containerPanel tr th:nth-of-type(6),
.shipmentTable .containerPanel tr th:nth-of-type(5) {
  width: 16rem;
}
.shipmentTable .containerPanel tr:nth-child(2n + 1) td:last-of-type {
  padding: 0.25rem 1rem;
}
.shipmentTable .containerPanel tr td,
.shipmentTable .containerPanel tr th {
  padding: 0.25rem 1rem;
  font-weight: 100;
  font-size: 0.75rem;
  letter-spacing: 0.5px;
  /* max-width: 0; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* chips */
.chipRow .MuiChip-root {
  border: 0;
}
/* loader */
.shipmentTable .loaderProgress tr:nth-child(odd) td {
  height: calc(100vh - 9rem);
  border: 0;
  text-align: center;
  border-radius: 1.5rem;
  background-color: transparent;
}
.docDetails > .container-fluid {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.docLoader {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottomLoader {
  text-align: center;
}
.shipmentTable.tableStOA.userList tr.deactivateUser td {
  background-color: #fad4d5;
}
.actionlist button:active,
.actionlist button:focus {
  border: 1px solid #3f51b580;
}
.shipmentDetailsComp {
  flex-grow: 1;
  position: relative;
}
.shipmentDetailsComp > div > div {
  border-radius: 1rem;
}
.shipmentDataDetailsCompParent {
  height: 100%;
  overflow-y: auto;
  padding-right: 1rem;
  position: relative;
}
.shipmentDataDetailsComp {
  display: flex;
  margin-bottom: 1rem;
}
.shipmentDataDetailsCompDetailsHeading {
  margin-bottom: 0;
  color: #111111;
  line-height: 1rem;
  font-size: 1rem;
  padding: 0.5rem;
  background: aliceblue;
}
.shipmentDataDetailsCompDetailsDivDetails {
  margin-top: 0.5rem;
}
.shipmentDataDetailsCompDetailsDivDetails p {
  padding: 0.25rem;
  background: #f2f2f2;
  margin-bottom: 0;
  font-size: 1rem;
}
.shipmentDataDetailsCompleft {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 0.5rem;
}
.shipmentDataDetailsCompleft img {
  padding: 0.4rem;
}
.shipmentDataDetailsCompleft img:last-of-type {
  padding-bottom: 0;
}
.shipmentDataDetailsCompRight {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.shipmentDataDetailsCompleft div {
  border-left: 2px dashed #94d247;
  flex-grow: 1;
  /* margin: 0.5rem 0; */
}
.shipmentDataDetailsCompRightTop span {
  color: #948fa2;
  font-size: 0.65rem;
}
.shipmentDataDetailsCompRightTop p {
  margin-bottom: 0;
  color: #111111;
  line-height: 0.85rem;
  font-size: 0.75rem;
}
.shipmentDataDetailsCompRightTableDiv {
  flex-grow: 1;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
}
.shipmentTable
  .containerPanel
  .shipmentDataDetailsCompRightTable
  tr
  td:nth-of-type(1) {
  max-width: 2.5rem;
  font-size: 0.8rem;
}
.shipmentTable .containerPanel .shipmentDataDetailsCompRightTable tr td,
.shipmentTable
  .containerPanel
  .shipmentDataDetailsCompRightTable
  tr:nth-child(2n + 1)
  td:last-of-type {
  padding: 0.2rem 0.5rem;
}
.shpmntdetails .col-12 > div.shipmentDetailsComp.loading {
  border: 0;
  justify-content: center;
  align-items: center;
}
.shipmentDetailsMapComp
  > div
  > div
  > div
  > div.gm-style
  > div
  > div.gmnoprint
  > div.gm-svpc {
  display: none;
}
.shipmentDetailsMapComp > div > div > div > div.gm-style a > div > img {
  display: none;
}
.shipmentDetailsMapComp > div > div > div .gm-style-cc {
  display: none;
}
/*.shipmentTable
  .containerPanel
  .shipmentDataDetailsCompRightTable
  tr
  td:nth-of-type(2) {
  max-width: 0;
} */
.version {
  margin-bottom: 0;
  font-weight: 600;
}
.shipmentTable
  .shipmentDataDetailsCompRightTable
  tr:nth-child(odd)
  td:last-of-type {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media only screen and (min-width: 768px) {
  .notify img {
    height: 100%;
  }
}
@media only screen and (min-width: 576px) {
  .page3 .notify {
    height: 100%;
  }
}
