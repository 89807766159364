div.selectOrgbutton.pointer,
div.selectOrgbutton.pointer:hover {
  font-size: 0.8rem;
  /* padding: 0.3rem 0.6rem; */
  background: #3d34561a;
  color: #111;
  border: 1px solid #ced4da;
  border-radius: 1.25rem;
}
.selectOrgParentAccordian.MuiAccordion-root:before {
  background-color: #fff;
}
.customized-menu ul {
  padding: 0;
}
.tableContainerParent {
  max-height: 25rem;
  width: 100%;
}
.tableContainer {
  max-height: 15rem;
}
.tableContainer th:nth-of-type(2) svg {
  display: none;
}
.tableContainer th,
.tableContainer td {
  border-bottom: 0;
}
.selectOrgParentAccordian.MuiPaper-elevation1 {
  box-shadow: none;
}
.selectOrgParentAccordian.MuiAccordion-root.Mui-expanded {
  margin-top: 0;
}
.selectOrgParentAccordian .selectOrgbutton,
.selectOrgbutton.MuiAccordionSummary-root.Mui-expanded,
.selectOrgParentAccordian .selectOrgbutton:hover {
  min-height: auto;
  height: 2rem;
  padding: 0;
}
.selectOrgParentAccordian .selectOrgbutton .MuiAccordionSummary-content {
  margin: 0;
}
.selectOrgParentAccordian .MuiAccordionDetails-root {
  /* padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 0; */
  padding: 0;
}
.shipmentTable.tableStOA.userList .tableContainer tr th:last-of-type {
  padding: 0;
  color: #111;
  background: #fff;
}
.shipmentTable .tableContainer table thead th.MuiTableCell-head,
.shipmentTable .tableContainer table thead th.MuiTableCell-head:first-of-type {
  padding: 0;
  background: #fff;
}
.shipmentTable .tableContainer table thead th.MuiTableCell-head svg {
  font-size: 1.2rem;
}
.shipmentTable.tableStOA
  .tableContainer
  table
  tbody
  tr
  td.MuiTableCell-root:first-of-type {
  border: 0;
  width: 2rem;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.shipmentTable .tableContainer tr:nth-child(odd) td span,
.shipmentTable .tableContainer tr:nth-child(even) td span {
  justify-content: flex-start;
  display: flex;
  align-items: center;
}
.shipmentTable.tableStOA.userList
  .tableContainer
  tr:nth-child(odd)
  td:last-of-type,
.shipmentTable.tableStOA
  .tableContainer
  table
  tbody
  tr:nth-child(even)
  td.MuiTableCell-root:last-of-type {
  border: 0;
  width: auto;
  padding-left: 0;
}
.shipmentTable.tableStOA
  .tableContainer
  table
  tbody
  tr
  td.MuiTableCell-root:first-of-type
  > span.MuiIconButton-colorSecondary:hover {
  background-color: transparent;
}
.selectOrgbutton input:focus {
  box-shadow: none;
}
.shipmentTable.tableStOA
  .tableContainer
  table
  tbody
  tr:nth-child(even)
  td.MuiTableCell-root:last-of-type,
.shipmentTable.tableStOA
  .tableContainer
  table
  tbody
  tr:nth-child(odd)
  td.MuiTableCell-root:last-of-type {
  white-space: pre-wrap;
  padding: 0.1rem;
}
.searchedOrganizationchips {
  display: flex;
  flex-direction: column;
  max-height: 7rem;
  overflow-y: auto;
  margin-bottom: 0.5rem;
}
.searchedOrganizationchips .MuiChip-root {
  justify-content: space-between;
  margin: 0.25rem 0;
  padding: 0.2rem 0.5rem 0.2rem 0;
  background: #fff;
  border: 1px solid #e0e0e0;
}
