.dashBoardWrapper {
  height: 100%;
}
.dashBoardWrapper > .container-fluid,
.dashBoardWrapper > .container-fluid > .row,
.dashBoardWrapper > .container-fluid > .row > .col-md-8,
.dashTable,
.dashTable .MuiTableContainer-root {
  height: 100%;
}
.dashDivData {
  height: 100%;
  border: 1px solid #eee;
  border-radius: 0.5rem;
  background-color: #fff;
}
.dashDivData > span {
  font-size: 0.85rem;
  margin: 0;
  color: #afafaf;
}
.dashDivData p {
  font-size: 1rem;
  line-height: 1.25rem;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.dashRightCol {
  padding: 0.3rem 0.75rem;
  height: calc(100% - 0rem);
  background-color: #fff;
  border-radius: 1rem;
  overflow-y: auto;
  border: 1px solid #eee;
}
.dashBoardWrapper input:focus,
.dashBoardWrapper select:focus {
  box-shadow: none;
}
.dashBoardWrapper .actions .saveAction button {
  background-color: #ee7821;
  color: #fff;
}
.dashBoardWrapper .actions .resetAction button {
  background-color: #3d3456;
  color: #fff;
}
.chartRow {
  height: calc(100% - 7.5rem);
  padding: 0.5rem;
  padding-bottom: 0;
}
.chartCol {
  height: 100%;
  overflow-x: auto;
  background: #fff;
  border-radius: 1rem;
  border: 1px solid #eee;
}
.bardata {
  height: 100%;
}
.sliderRow {
  overflow-x: auto;
  /* overflow-y: hidden; */
}
.sliderRow .MuiSlider-root {
  margin-bottom: 0;
  margin-top: 1.25rem;
  /* width: calc(100% - 10px); */
}
.sliderRow .MuiSlider-markLabel {
  top: -1rem;
  transform: translate(0px, 0px);
}
.sliderRow .MuiSlider-thumb::after {
  display: none;
}
.stDateEnDate {
  font-size: 0.85rem;
}
.MuiButtonBase-root.MuiRadio-colorSecondary.Mui-checked {
  color: #ee7821;
}
.shpCountDivData .col-12 {
  min-height: 100%;
}
.dashTable tbody td:first-of-type {
  max-width: 15rem;
}
.dashTable tbody td:last-of-type {
  min-width: 8rem;
}
.dashTable th,
.dashTable td {
  padding: 0.75rem 0.5rem;
}
.dashRightCol label.form-label {
  margin-bottom: 0.25rem;
}
